import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId,
    getTicketTypeNumberOfAvailableTickets, getEventGroupsOfEvent
} from "ticketino-api-client";
import { Calendar } from "react-calendar";
import { toZonedTime } from 'date-fns-tz';

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import InformationAndTariff from "./components/InformationAndTariff";
import imgIconProgress2 from '../img/icon-progress2.svg';
import imgIconProgressGrey from '../img/icon-progress-grey.svg';
import imgIconProgressTick from '../img/icon-progress-tick.svg';

import "../css/style.css";
import "../css/calendar.css";
import "../css/ticketing.css";

const Step15_Tickets_Abramovic = () => {
    const [posId, setPosId] = useState(null);

    const [event, setEvent] = useState({});
    const [eventDate, setEventDate] = useState({});

    const [ticketTypes, setTicketTypes] = useState([]);
    const [unlockedTicketTypes, setUnlockedTicketTypes] = useState([]);
    const [isTicketTypesUnlocked, setIsTicketTypesUnlocked] = useState(false);
    const [eventGroupTours, setEventGroupTours] = useState([]);
    const [notAllowedPromotionCodes, setNotAllowedPromotionCodes] = useState([]);

    const [orderId, setOrderId] = useState(0);
    const [order, setOrder] = useState({});

    //const [ticketTypeGroupIdAllInclusive, setTicketTypeGroupIdAllInclusive] = useState(0);
    //const [ticketTypeIdsOfAllInclusive, setTicketTypeIdsOfAllInclusive] = useState([]);

    const [promotioncode, setPromotioncode] = useState("");
    const [promotioncodeAdded, setPromotioncodeAdded] = useState("");
    const [promotionError, setPromotionError] = useState("");
    const [isPromotioncodeApplied, setIsPromotioncodeApplied] = useState("");
    const [isTourEvent, setIsTourEvent] = useState(true);
    const [isWongExhibitionOver, setIsWongExhibitionOver] = useState(false);

    const [isUpgradeClick, setIsUpgradeClick] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(true);

    const [beginTime, setBeginTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const timeZone = 'Europe/Zurich';

    const endDateOfWongExhibition = new Date(2025, 0, 26, 23, 59, 59); // 26.01.2025
    const zurichEndDateOfWongExhibition = toZonedTime(endDateOfWongExhibition, timeZone); 

    const { language, eventId } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    let navigate = useNavigate();
    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {
        const storedBeginTime = sessionStorage.getItem("BeginTimeAbramovic");
        const storedEndTime = sessionStorage.getItem("EndTimeAbramovic");

        if (storedBeginTime && storedEndTime) {
            setBeginTime(storedBeginTime);
            setEndTime(storedEndTime);
        }
    }, []);


    useEffect(() => {

        setLoading(true);
        const loadToken = async () => {
            try {
                const res = await axios.get("form/token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
                api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
                await sessionStorage.setItem("token", res.data);
                requestFormSettings();
            } catch (error) {
                console.error(error);
            }
        };

        let currentOrderId = sessionStorage.getItem("OrderId");

        if (currentOrderId > 0) {
            setOrderId(currentOrderId);
        }

        const requestFormSettings = async () => {
            try {
                const res = await axios.get(`form/formsettings`);

                if (currentOrderId > 0) {
                    loadOrder(currentOrderId)
                } else {
                    startOrder(res.data.posId);
                }

                setPosId(res.data.posId);

                setNotAllowedPromotionCodes(res.data.notAllowedPromotionCodes);
                //setTicketTypeGroupIdAllInclusive(res.data.ticketTypeGroupIdAllInclusive);

                const eventGroups = [res.data.eventGroupIdTourDE, res.data.eventGroupIdTourEN, res.data.eventGroupIdTourFR];
                setEventGroupTours(eventGroups);

            } catch (error) {
                console.error(error);
            }
        };

        const requestResources = async () => {
            try {
                const res = await axios.get(`form/resources/${language}`);
                setResources(res.data?.translation);
            } catch (error) {
                console.error(error);
            }
        };

        loadToken();
        requestResources();
        setLoading(false);
    }, [language]);

    useEffect(() => {
        if (order && orderId > 0 && eventId > 0) {
            loadEvent(eventId, orderId)
        }
    }, [order, orderId, promotioncodeAdded]);

    useEffect(() => {
        if (event.start) {

            let localLanguageString = 'de-DE';

            if (language === "en") {
                localLanguageString = 'en-US'
            }
            else if (language === "fr") {
                localLanguageString = 'fr-FR'
            }

            const date = new Date(event.start);
            const formattedDate = date.toLocaleDateString(localLanguageString, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });


            setEventDate(formattedDate);  // Ensure this is always a string
        }
    }, [event, language]);

    const startOrder = async (posId) => {

        const order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId ?? 6585,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const res = await axios.post(`${baseUrl}/ShopBasket/Order`, order);

            await sessionStorage.setItem("OrderId", res.data.id);

            setOrderId(res.data.id);

            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);

            //const bookedTickets = updatedOrder.tickets;

            // Booked Tickets
            //const updatedTickets = await Promise.all(
            //    bookedTickets.map(async (ticket) => {
            //        const ticketType = await getTicketTypeById(ticket.ticketTypeId);

            //        const info = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
            //        const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

            //        return { ...ticket, ticketType: ticketType, info: ticketTypeInfo }
            //    })
            //)

            setOrder(updatedOrder);

            //validate page if user can proceed
            let quantity = 0;

            if (updatedOrder != null && updatedOrder.tickets != null) {
                quantity = updatedOrder.tickets.length;
            }

            if (quantity > 0) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
            if (error.response.status === 404 || error.response.status === 403) {
                const newOrder = await startOrder(posId);
                return newOrder;
            }
        }
    }

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            const eventStart = new Date(event.start);

            if (eventStart.getTime() > zurichEndDateOfWongExhibition.getTime()) {
                setIsWongExhibitionOver(true);
            }

            // sorting it according to the language
            const eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            let eventGroupIdsOfEvent = await getEventGroupsOfEvent(eventId);

            const isTourEvent = eventGroupIdsOfEvent.some(id => eventGroupTours.includes(id))

            setIsTourEvent(isTourEvent);

            // TicketType
            const ticketTypes = await getTicketTypesByEventId(eventId);


            let updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId, promotioncodeAdded);

                    //check if its all inclusive ticket type
                    const isAllInclusiveTicketType = ticketTypeInfo?.name != null
                        ? ticketTypeInfo.name.toLowerCase().includes("all in one")
                        : true;

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return {
                        ...ticketType,
                        info: ticketTypeInfo,
                        availability: ticketTypeAvailability,
                        isAllInclusiveTicketType: isAllInclusiveTicketType
                    }
                })
            );

            //set all inclusive ticket types
            updatedTicketTypes = updatedTicketTypes.map(ticketType => {

                if (ticketType.isAllInclusiveTicketType) return { ...ticketType, allInclusiveTicketTypeId: null };

                const allInclusiveTicketType = updatedTicketTypes.find(tt => {
                    const name = tt.info.name.toLowerCase();

                    //the name must contain the orginal name plus "all in one", that's how we related the original name to the all inclusive one
                    return name.includes(ticketType.info.name.toLowerCase()) && name.includes("all in one");
                });

                return {
                    ...ticketType,
                    allInclusiveTicketTypeId: allInclusiveTicketType?.id
                };
            });

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    const onTicketTypeChange = (e, ticketTypeId) => {
        setLoading(true);

        if (!isTourEvent && (!beginTime || beginTime == null || !endTime || endTime == null)) {
            navigate(`/${language}/home`);
            return;
        }

        const ticketToAdd = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
            timOfEntryFrom: !isTourEvent ? beginTime : null,
            timOfEntryTo: !isTourEvent ? endTime : null
        };

        handleTicketsFromBasket(ticketToAdd);
    };

    const handleTicketsFromBasket = async (ticketToAdd) => {

        if (order != null && order.tickets != null) {
            let ticketTypeIdsToRemove = order.tickets
                .filter(ticket => ticket.ticketTypeId === ticketToAdd.ticketTypeId)
                .map(ticket => ticket.id);

            // DELETE request to remove tickets
            await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                data: { TicketsToRemove: ticketTypeIdsToRemove }
            });
        }

        let addTicketTypes = [];
        addTicketTypes.push(ticketToAdd);

        addTicketTypes = addTicketTypes.map((att) => {
            if (unlockedTicketTypes.includes(att.ticketTypeId)) {
                att.promotionCode = promotioncodeAdded;
            }
            return att;
        })

        //google tag manager add or remove tickets to cart
        let ticketType = ticketTypes.find(tt => tt.id === ticketToAdd.ticketTypeId);
        if (ticketType && ticketToAdd) {

            let ticketsWithSameTicketTypeId = [];

            if (order.tickets) {
                ticketsWithSameTicketTypeId = order.tickets.filter(t => t.ticketTypeId === ticketToAdd.ticketTypeId)
            }

            if (ticketsWithSameTicketTypeId.length < ticketToAdd.quantity) {
                const quantity = ticketToAdd.quantity - ticketsWithSameTicketTypeId.length;

                // gtm - add_to_cart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'add_to_cart',
                    'items': [
                        {
                            'item_id': ticketType.id,
                            'item_name': event.info?.name,
                            'item_variant': ticketType.info?.name,
                            'price': ticketType.price,
                            'quantity': quantity
                        }
                    ]
                });
            } else {
                const quantity = ticketsWithSameTicketTypeId.length - ticketToAdd.quantity;

                // gtm - remove_from_cart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'remove_from_cart',
                    'items': [
                        {
                            'item_id': ticketType.id,
                            'item_name': event.info?.name,
                            'item_variant': ticketType.info?.name,
                            'price': ticketType.price,
                            'quantity': quantity
                        }
                    ]
                });
            }
        }

        if (ticketToAdd.quantity > 0) {
            await axios
                .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    ticketsToAdd: addTicketTypes,
                })
                .then(() => {
                    //// gtm - addToCart
                    //window.dataLayer = window.dataLayer || [];
                    //window.dataLayer.push({
                    //    'event': 'addToCart'
                    //});
                })
                .catch((error) => {
                    console.error(error.response.data);
                });
        }

        loadOrder(orderId);
    };

    const applyPromotionCodeToOrder = async (orderId, code) => {

        if (notAllowedPromotionCodes.includes(code.toLowerCase())) {
            setPromotionError(resources?.PromoCodeNotAllowed);
            return;
        }

        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            setIsPromotioncodeApplied(true);

            if (response?.data?.unlockedTicketTypes == null) {
                //when all the added tickets are unavailable we must display an error
                setPromotionError(resources?.PromoCodeBookedForToday);
            }

            return response.data;
        } catch (error) {
            console.error(error.response.data.replace("_KH", ''));
            setPromotionError(error.response.data.replace("_KH", ''));
            //setPromotionError(resources.detailPage?.errorMessage);
            setIsPromotioncodeApplied(false);
        }
    }

    const deletePromotionCodeFromOrder = async (orderId, code) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            setPromotioncode("");
            setPromotioncodeAdded("");
            setIsPromotioncodeApplied(false);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const getTicketTypeAvailability = async (ticketTypeId, orderId, promotionCode) => {
        try {
            const response = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}&promotionCode=${promotionCode}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const upgradeOrderToAllInclusiveTickets = async () => {

        if (order?.tickets == null) return;
        if (order.tickets.length === 0) return;

        //get the ticket type ids + quantity of tickets that we need to replace
        let allInclusiveTicketsToAdd = {};

        order.tickets.forEach(async (t) => {

            const ticketType = ticketTypes.find(tt => tt.id === t.ticketTypeId);

            if (allInclusiveTicketsToAdd[ticketType.allInclusiveTicketTypeId]) {
                allInclusiveTicketsToAdd[ticketType.allInclusiveTicketTypeId]++;
            }
            else {
                allInclusiveTicketsToAdd[ticketType.allInclusiveTicketTypeId] = 1;
            }
        });

        //map the api models to book the "all in one" tickets
        let ticketsToAdd = [];

        for (const [key, value] of Object.entries(allInclusiveTicketsToAdd)) {

            const ticketToAdd = {
                ticketTypeId: key,
                quantity: value,
                timOfEntryFrom: beginTime ?? null,
                timOfEntryTo: endTime ?? null
            };

            ticketsToAdd.push(ticketToAdd);
        }


        //delete all tickets from the order
        const ticketTypeIdsToRemove = order.tickets.map(ticket => ticket.id);

        await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
            data: { TicketsToRemove: ticketTypeIdsToRemove }
        });

        //add new "all in one" tickets
        if (ticketsToAdd.length > 0) {

            await axios
                .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    ticketsToAdd: ticketsToAdd,
                })
                .then(() => {

                })
                .catch((error) => {
                    console.error(error.response.data);
                });

        }

        await sessionStorage.setItem("BeginTimeAllInclusive", beginTime);
        await sessionStorage.setItem("EndTimeAllInclusive", endTime);

        //redirect to the all inclusive page
        navigate(`/${language}/all-inclusive-tickets/${eventId}`);
    }

    const addPromotionToOrder = async () => {

        setPromotionError("");
        setLoading(true);

        //to prevent collisions with the current codes stored in our system we added the suffix "_KH" to the imported promotion codes to out DB.
        //the suffix is only added to the imported promotions, the randomly generated ones (the non numeric) won't hae the suffix in our DB
        const parsedPromotionCode = isNaN(promotioncode) ? promotioncode : `${promotioncode}_KH`;

        const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(orderId, parsedPromotionCode);
        let updatedTicketTypes = [];

        updatedTicketTypes = await Promise.all(ticketTypes.map(async (ticketType) => {
            if (updatedUnlockedTicketTypes?.unlockedTicketTypes?.includes(ticketType.id)) {
                const unlockedTicketTypeAvailability = await getTicketTypeAvailability(ticketType.id, orderId, parsedPromotionCode);
                ticketType.availability = unlockedTicketTypeAvailability;
            }
            return ticketType;
        }))

        ////when all the added tickets are unavailable we must display an error
        //const allTicketTypesNotAvailable = updatedTicketTypes.filter(tt => updatedUnlockedTicketTypes?.unlockedTicketTypes?.includes(tt.id)).every(tt => tt.availability?.availableTickets === 0);
        //if (allTicketTypesNotAvailable) setPromotionError(resources?.PromoCodeBookedForToday);

        await sessionStorage.setItem("addedPromotion", parsedPromotionCode);
        setPromotioncodeAdded(parsedPromotionCode);

        setTicketTypes(updatedTicketTypes)
        setUnlockedTicketTypes(updatedUnlockedTicketTypes?.unlockedTicketTypes ?? []);
        setIsTicketTypesUnlocked(true);
        setLoading(false);
    }

    const removePromotionFromOrder = async () => {

        setPromotionError("");
        setLoading(true);

        //delete the tickets with the selected promotion
        if (order?.tickets) {
            const ticketTypeIdsToRemove = order.tickets.filter(t => t.promotionCode === promotioncodeAdded).map(ticket => ticket.id);

            if (ticketTypeIdsToRemove.length > 0) {
                const updatedOrder = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    data: { TicketsToRemove: ticketTypeIdsToRemove }
                });

                setOrder(updatedOrder);
            }
        }

        await deletePromotionCodeFromOrder(orderId, promotioncodeAdded);

        await loadEvent(eventId, orderId);

        setUnlockedTicketTypes([]);
        setIsTicketTypesUnlocked(false);
        setLoading(false);
    }

    const navigateToShipping = async () => {

        // gtm - begin_checkout
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'begin_checkout'
        });

        navigate(`/${language}/shipping`);
    }

    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        //remove all inclusive ticket types
        ticketTypesSorted = ticketTypesSorted.filter((tts) => tts.isAllInclusiveTicketType === false);


        return (
            <div style={{ position: 'relative', opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'auto' }}>
                {ticketTypes &&
                    ticketTypes.length > 0 &&
                    ticketTypesSorted.map((tt, index) =>
                        (tt.availability?.availableTickets + (order.tickets?.filter((t) => t.ticketTypeId === tt.id)?.length ?? 0)) > 0 ? (
                            <div key={index} className="row pt-2 pb-2 padding-left-25 padding-right-25"
                                style={{ backgroundColor: index % 2 === 0 ? 'transparent' : '#EFF2F3' }}>
                                <div className="col-6 col-md-7 text-16-18 black-color text-start d-flex align-items-center padding-0">
                                    <label>{tt.info?.name}</label>
                                </div>
                                <div className="col-1 col-md-2 text-16-18 black-color d-flex justify-content-end align-items-center">
                                    <label>{tt.currency}</label>
                                </div>
                                <div className="col-2 col-md-2 text-16-18 black-color d-flex justify-content-start align-items-center">
                                    <label className="price-label text-end">{tt.price.toFixed(2)}</label>
                                </div>
                                <div className="col-3 col-md-1 text-16-18 black-color d-flex justify-content-start align-items-center padding-0">
                                    <select
                                        className="form-select"
                                        value={order && order.tickets ? order.tickets.filter((t) => t.ticketTypeId === tt.id).length : 0}
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                        disabled={loading}
                                    >
                                        {Array.from(
                                            Array(
                                                tt.availability?.availableTickets +
                                                1 +
                                                (order.tickets ? order.tickets.filter((t) => t.ticketTypeId === tt.id).length : 0)
                                            ),
                                            (e, i) => (
                                                <option key={i} value={i}>
                                                    {i}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>
                        ) : null
                    )}
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 10,
                        }}
                    >
                        <Loading bgColor="transparent" color="#d3d3d3" position="relative" />
                    </div>
                )}
            </div>
        );
    };


    const getUpgradePrice = () => {


        var upgradeTotalPrice = 0;

        if (order && order.tickets) {

            for (let i = 0; i < order.tickets.length; i++) {
                if (order.tickets[i].price === 31)
                    upgradeTotalPrice += 5;
                else if (order.tickets[i].price === 22) {
                    upgradeTotalPrice += 3;
                }
            }
        }
        return upgradeTotalPrice;
    }


    const getUpgradeButtonText = () => {

        let text = resources?.UpgradeButtonText || '';

        var upgradeTotalPrice = getUpgradePrice();
        return text.replace('{TotalUpgradePrice}', upgradeTotalPrice);

    }

    const getUpgradeDescriptionText = () => {

        let text = resources?.UpgradeTicketDescription || '';

        var upgradeTotalPrice = getUpgradePrice();
        return text.replace('{TotalUpgradePrice}', upgradeTotalPrice);
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            {/*{loading && <Loading alignment="center" color="#d3d3d3" bgColor="transparent" position="fixed" top="300px" />}*/}
            <>
                <Navbar numberOfTickets={order?.tickets?.length ?? 0} isShoppingCartVisible={true} pagePath="marina-abramovic-tickets" eventId={eventId} />
                <main className="flex-fill">
                    {resources && (
                        <div className="container page-wrapper">

                            <div className="progress-container">
                                <div className="progress-step active">
                                    <div>
                                        <div className="image cursor-pointer ">
                                            <img src={imgIconProgressTick} alt="Step 1" onClick={() => navigate(`/${language}/marina-abramovic`)} />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.DateAndTime}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step completed">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgress2} alt=" Step 2" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.TicketSelection}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgressGrey} alt="Step 3" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.SummaryAndContact}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgressGrey} alt="Step 4" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.PaymentAndDelivery}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 sticky-wrapper">
                                <div className="col-md-8 padding-0">
                                    <p className="text-50-55 black-color font-500">{resources?.MariaAbramovic}</p>
                                    <p className="text-30-36-bold black-color">{resources?.AbramovicEventDateRange}</p>
                                </div>
                            </div>

                            <div className="row margin-top-30">
                                <div className="col-md-12 div-container-blue">
                                    <div className="row padding-left-25 padding-right-25">
                                        <div className="col-md-12 div-container-blue padding-0">
                                            <p className="text-20-24 font-900 black-color mt-3 pb-0">{typeof eventDate === 'string' ? eventDate : ''}</p>
                                            {beginTime != null && endTime != null && <p className="text-20-24 black-color">{beginTime.slice(0, 5)} - {endTime.slice(0, 5)} Uhr</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {((beginTime && endTime && beginTime != null && endTime != null) || isTourEvent) &&
                                mapTicketTypes()}

                            {/* upgrade ticket mobile*/}
                            {!isTourEvent && !isWongExhibitionOver && order?.tickets?.length > 0 &&
                                <div className="row visible-mobile">
                                    <div className="col-12">
                                        <div className="accordion" id="accordion-upgrade-ticket">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingUpgradeTicket">
                                                    <button
                                                        className="accordion-button collapsed accordion-button-exhibition"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseUpgradeTicket"
                                                        aria-expanded="false"
                                                        aria-controls="collapseUpgradeTicket"
                                                    >
                                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        <label className="accordion-button-text">
                                                            <p className="text-40-45 font-900 text-uppercase black-color mt-3">{resources?.UpgradeToAllInOneTicket}</p>
                                                        </label>
                                                    </button>
                                                </h2>
                                                <div id="collapseUpgradeTicket" className="accordion-collapse collapse" aria-labelledby="headingUpgradeTicket" data-bs-parent="#accordion-exhibitions-mobile">
                                                    <div className="accordion-body">
                                                        <p className="text-22-27 dark-grey-color font-500">{getUpgradeDescriptionText()}</p>
                                                        <div className="d-flex justify-content-center">
                                                            <button className="btn grey-button mt-3 mb-2 d-flex" onClick={() => upgradeOrderToAllInclusiveTickets(!isUpgradeClick)}>
                                                                {getUpgradeButtonText()}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            {/* Upgrade ticket / Mitglieder ticket - desktop */}
                            <div className="row margin-top-55 visible-desktop">

                                {/* Upgrade ticket desktop */}
                                {!isTourEvent && !isWongExhibitionOver && order?.tickets?.length > 0 &&
                                    <div className="col-md-6 p-0 border-right-20">
                                        <div className="div-container-blue padding-25">
                                            <p className="text-30-37 font-900 text-uppercase black-color">{resources?.UpgradeToAllInOneTicket}</p>
                                            <div className="grey-line" ></div>
                                            <p className="text-22-27 mt-3 dark-grey-color font-500">{getUpgradeDescriptionText()}</p>
                                            <div className="d-flex justify-content-center">
                                                <button className="btn grey-button mt-5 d-flex" onClick={() => upgradeOrderToAllInclusiveTickets(!isUpgradeClick)}>
                                                    {getUpgradeButtonText()}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* Mitglieder ticket - desktop */}
                                <div className={`${!isTourEvent && !isWongExhibitionOver && order?.tickets?.length > 0 ? 'col-md-6' : 'col-md-12'} div-container-blue padding-25`}>
                                    <p className="text-30-37 font-900 text-uppercase black-color">{resources?.MitgliederTicket}</p>
                                    <p className=" text-16-18 black-color ">{resources?.MitgliederTicketDescription1}</p>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="flex-grow-1 me-3">
                                                    <input
                                                        className="form-control"
                                                        placeholder={resources?.MitgliederNumberOrCode}
                                                        value={promotioncode}
                                                        onChange={(e) => setPromotioncode(e.target.value)}
                                                        disabled={isPromotioncodeApplied}
                                                    />
                                                </div>
                                                <div>
                                                    <button className="btn grey-button" onClick={() => addPromotionToOrder()}>
                                                        {resources?.Redeem}
                                                    </button>
                                                </div>
                                                {isPromotioncodeApplied && (
                                                    <div>
                                                        <button className="btn grey-button ms-3" onClick={() => removePromotionFromOrder()}>
                                                            {resources?.Remove}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {promotionError != null && promotionError !== "" && <p className="red-color">{promotionError}</p>}
                                            {(promotionError == null || promotionError === "")
                                                && promotioncodeAdded != null
                                                && promotioncodeAdded !== ""
                                                && Array.isArray(ticketTypes)
                                                && ticketTypes?.length > 0
                                                && !(ticketTypes.some((tt => tt.availability?.availableTickets + (order?.tickets?.length > 0 ? (order?.tickets?.filter((t) => t.ticketTypeId === tt.id)?.length ?? 0) : 0) > 0)))
                                                && <p className="red-color">{resources?.NoTicketTypesWithPromotionCode}</p>
                                            }
                                            {(promotionError == null || promotionError === "")
                                                && promotioncodeAdded != null
                                                && promotioncodeAdded !== ""
                                                && Array.isArray(ticketTypes)
                                                && ticketTypes.length > 0 &&
                                                (ticketTypes.some((tt => tt.availability?.availableTickets + (order?.tickets?.length > 0 ? (order?.tickets?.filter((t) => t.ticketTypeId === tt.id)?.length ?? 0) : 0) > 0)))
                                                &&
                                                <p className="green-color">{resources?.SelectTicketTypeNumber}</p>
                                            }
                                        </div>
                                    </div>
                                    <p className=" text-16-18 black-color mb-2 padding-bottom-10">{resources?.MitgliederTicketDescription2}</p>

                                    <div className="accordion" id="accordion-memberships">
                                        <div className="accordion-item accordion-item-grey">
                                            <h2 className="accordion-header" id="headingMemberships">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseMemberships"
                                                    aria-expanded="false"
                                                    aria-controls="collapseMemberships"
                                                >
                                                    <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <label className="membership-title">
                                                        {resources?.AcceptedMembers}
                                                    </label>
                                                </button>
                                            </h2>
                                            <div id="collapseMemberships" className="accordion-collapse collapse" aria-labelledby="headingMemberships" data-bs-parent="#accordion-memberships">
                                                <div className="accordion-body">
                                                    <ul className="list-unstyled">
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint1}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint2}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint3}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint4}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint5}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint6}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint7}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint8}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint9}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint10}</li>
                                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint11}</li>
                                                        <li>{resources?.MitgliederTicketpoint12}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Mitglieder ticket mobile*/}
                            <div className="row visible-mobile">
                                <div className="col-12">
                                    <div className="accordion" id="accordion-mitglieder-ticket">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingMitgliederTicket">
                                                <button
                                                    className="accordion-button collapsed accordion-button-exhibition"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseMitgliederTTicket"
                                                    aria-expanded="false"
                                                    aria-controls="collapseMitgliederTTicket"
                                                >
                                                    <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <label className="accordion-button-text">
                                                        <p className="text-40-45 font-900 text-uppercase black-color mt-3">{resources?.MitgliederTicket}</p>
                                                    </label>
                                                </button>
                                            </h2>
                                            <div id="collapseMitgliederTTicket" className="accordion-collapse collapse" aria-labelledby="headingMitgliederTTicket" data-bs-parent="#accordion-mitglieder-ticket">
                                                <div className="accordion-body">
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            <p className=" text-16-18 black-color ">{resources?.MitgliederTicketDescription1}</p>
                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <input className="form-control" placeholder={resources?.MitgliederNumberOrCode} value={promotioncode}
                                                                        onChange={(e) => setPromotioncode(e.target.value)}
                                                                        disabled={isPromotioncodeApplied} />

                                                                    <p>{promotionError}</p>
                                                                </div>
                                                                <div className="col-12 mb-2 pe-0">
                                                                    <div className="row">
                                                                        <div className="col text-start ">
                                                                            <button className="btn grey-button" onClick={() => { addPromotionToOrder() }}>{resources?.Redeem}</button>
                                                                            {isPromotioncodeApplied &&
                                                                                <button className="btn grey-button ms-3" onClick={() => { removePromotionFromOrder() }}>{resources?.Remove}</button>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className=" text-16-18 black-color mt-2 mb-2 padding-bottom-10">{resources?.MitgliederTicketDescription2}</p>
                                                            <div>
                                                                <ul className="list-unstyled">
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint1}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint2}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint3}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint4}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint5}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint6}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint7}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint8}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint9}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint10}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint11}</li>
                                                                    <li className="padding-bottom-15">{resources?.MitgliederTicketpoint12}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-7 col-6 text-18-21 black-color d-flex justify-content-start align-items-center padding-0">{resources.Total}:</div>
                                <div className="col-1 col-md-2 text-18-21 black-color d-flex justify-content-end align-items-center">
                                    <label className="font-600">
                                        CHF
                                    </label>
                                </div>
                                <div className="col-2 col-md-1 text-18-21 black-color d-flex justify-content-start align-items-center">
                                    <label className="font-600 price-label text-end"> {order && order.total && order.total !== null ? (
                                        <span>{(order.total).toFixed(2)}</span>
                                    ) : (
                                        <span>0.00</span>
                                    )}
                                    </label>
                                </div>
                            </div>

                            {(order && (order.tickets === undefined || order.tickets.length === 0)) && (
                                <div className="row mt-2">
                                    <div className="col-md-7 col-6 text-18-21 red d-flex justify-content-start align-items-center padding-0">{resources?.TicketChoose}</div>
                                </div>
                            )}

                            <div className="row mt-5 mb-5">
                                <div className="col-6 d-flex justify-content-start align-items-center padding-0">
                                    <button className="btn transperant-grey-button" onClick={() => navigate(-1)}>
                                        {resources?._Back}
                                    </button>
                                </div>
                                <div className="col-6 d-flex justify-content-end align-items-center padding-0">
                                    <button
                                        className="btn grey-button "
                                        disabled={!isValid}
                                        onClick={() => navigateToShipping()}
                                    >
                                        {resources?.BookNow}
                                    </button>
                                </div>
                            </div>
                            <InformationAndTariff />
                        </div>
                    )}
                </main>
                <Footer />
            </>
        </div >
    );
};

export default Step15_Tickets_Abramovic;