import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const InformationAndTariff = () => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.informationAndTariff);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <>
            <div className="visible-desktop">
                <div className="row">
                    <div className="col-12 padding-0">
                        <p className="text-50-55 font-500 black-color">{resources?.Tarife}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8 padding-0">
                        <div className="accordion" id="accordion-information-tariffe">

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingInfoOne">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseInfoOne"
                                        aria-expanded="false"
                                        aria-controls="collapseInfoOne"
                                    >
                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label className="accordion-button-text">
                                            {resources?.Tarife2}
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseInfoOne" className="accordion-collapse collapse show" aria-labelledby="headingInfoOne" data-bs-parent="#accordion-information-tariffe">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled">
                                            <li className="padding-bottom-15">{resources?.Tarife2Description1}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description2}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description3}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description4}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description5}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description6}</li>
                                            <li className="padding-bottom-15" dangerouslySetInnerHTML={{ __html: resources?.Tarife2Description7 }}></li>
                                        </ul>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: resources?.Tarife2Description8 }}
                                        ></p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label className="accordion-button-text">
                                            {resources?.Tarife3}
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion-information-tariffe">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled">
                                            <li className="padding-bottom-15">{resources?.Tarife3Description1}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description2}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description3}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description4}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description5}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description6}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description8}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description9}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description10}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description11}</li>
                                        </ul>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: resources?.Tarife3Description12 }}
                                        ></p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFour">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                    >
                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label className="accordion-button-text">
                                            {resources?.Tarife4}
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordion-information-tariffe">
                                    <div className="accordion-body">
                                        <p dangerouslySetInnerHTML={{ __html: resources?.Tarife4Description1 }}  ></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <p className="text-40-45 font-900 black-color">{resources?.NavbarOpeningTime}</p>
                        <div className="mt-3">
                            <div className="accordion-body accordion-body-nav">
                                <ul className="list-unstyled">
                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime1Title}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text1}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text2}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text3}</li>
                                </ul>
                                <span>&nbsp;</span>
                                <ul className="list-unstyled">
                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime2Title}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime2Text2}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime2Text3}</li>
                                </ul>
                                <span>&nbsp;</span>
                                <ul className="list-unstyled">
                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime3Title}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime3Text1}</li>
                                </ul>
                                <span>&nbsp;</span>
                                <ul className="list-unstyled">
                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime4Title}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime4Text1}</li>
                                </ul>
                                <span>&nbsp;</span>
                                <ul className="list-unstyled">
                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime5Title}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime5Text1}</li>
                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime5Text2}</li>
                                </ul>
                                <span>&nbsp;</span>
                                <p id="shopOpeningTime"
                                    dangerouslySetInnerHTML={{ __html: resources?.NavbarShopOpeningTimeDescr }}
                                ></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile - tarriff */}
            <div className="visible-mobile">
                <div className="sammlung-title visible-mobile">
                    <p className="text-50-55 font-500 black-color">{resources?.Tarife}</p>
                </div>
                <div className="row visible-mobile">
                    <div className="col-12">
                        <div className="accordion" id="accordion-information-tariffe-mobile">

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwoMobile">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwoMobile"
                                        aria-expanded="false"
                                        aria-controls="collapseTwoMobile"
                                    >
                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label className="accordion-button-text">
                                            {resources?.Tarife2}
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseTwoMobile" className="accordion-collapse collapse" aria-labelledby="headingTwoMobile" data-bs-parent="#accordion-information-tariffe-mobile">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled">
                                            <li className="padding-bottom-15">{resources?.Tarife2Description1}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description2}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description3}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description4}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description5}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife2Description6}</li>
                                            <li className="padding-bottom-15" dangerouslySetInnerHTML={{ __html: resources?.Tarife2Description7 }}></li>
                                        </ul>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: resources?.Tarife2Description8 }}
                                        ></p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreeMobile">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThreeMobile"
                                        aria-expanded="false"
                                        aria-controls="collapseThreeMobile"
                                    >
                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label className="accordion-button-text">
                                            {resources?.Tarife3}
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseThreeMobile" className="accordion-collapse collapse" aria-labelledby="headingThreeMobile" data-bs-parent="#accordion-information-tariffe-mobile">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled">
                                            <li className="padding-bottom-15">{resources?.Tarife3Description1}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description2}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description3}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description4}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description5}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description6}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description8}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description9}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description10}</li>
                                            <li className="padding-bottom-15">{resources?.Tarife3Description11}</li>
                                        </ul>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: resources?.Tarife3Description12 }}
                                        ></p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFourMobile">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFourMobile"
                                        aria-expanded="false"
                                        aria-controls="collapseFourMobile"
                                    >
                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label className="accordion-button-text">
                                            {resources?.Tarife4}
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseFourMobile" className="accordion-collapse collapse" aria-labelledby="headingFourMobile" data-bs-parent="#accordion-information-tariffe-mobile">
                                    <div className="accordion-body">
                                        <p dangerouslySetInnerHTML={{ __html: resources?.Tarife4Description1 }}  ></p>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading-opening-Time">
                                    <button
                                        className="accordion-button collapsed accordion-button-openingTime"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOpeningTimes"
                                        aria-expanded="false"
                                        aria-controls="collapseOpeningTimes"
                                    >
                                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <label className="accordion-button-text">
                                            <p className="exhibition-title mb-0">{resources?.NavbarOpeningTime}</p>
                                        </label>
                                    </button>
                                </h2>
                                <div id="collapseOpeningTimes" className="accordion-collapse collapse" aria-labelledby="heading-opening-Time" data-bs-parent="#accordion-exhibitions-mobile">
                                    <div className="accordion-body">
                                        <div>
                                            <div className="accordion-body accordion-body-nav">
                                                <ul className="list-unstyled">
                                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime1Title}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text1}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text2}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime1Text3}</li>
                                                </ul>
                                                <span>&nbsp;</span>
                                                <ul className="list-unstyled">
                                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime2Title}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime2Text2}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime2Text3}</li>
                                                </ul>
                                                <span>&nbsp;</span>
                                                <ul className="list-unstyled">
                                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime3Title}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime3Text1}</li>
                                                </ul>
                                                <span>&nbsp;</span>
                                                <ul className="list-unstyled">
                                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime4Title}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime4Text1}</li>
                                                </ul>
                                                <span>&nbsp;</span>
                                                <ul className="list-unstyled">
                                                    <li className="accordion-nav-sub">{resources?.NavbarOpeningTime5Title}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime5Text1}</li>
                                                    <li className="accordion-nav-text">{resources?.NavbarOpeningTime5Text2}</li>
                                                </ul>
                                                <span>&nbsp;</span>
                                                <p id="shopOpeningTime"
                                                    dangerouslySetInnerHTML={{ __html: resources?.NavbarShopOpeningTimeDescr }}
                                                ></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default InformationAndTariff