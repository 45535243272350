import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId,
    getTicketTypeNumberOfAvailableTickets, getTicketTypeById, getTimeSlotsByEventId
} from "ticketino-api-client";
import { Calendar } from "react-calendar";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import InformationAndTariff from "./components/InformationAndTariff";
import imgIconProgress2 from '../img/icon-progress2.svg';
import imgIconProgressGrey from '../img/icon-progress-grey.svg';
import imgIconProgressTick from '../img/icon-progress-tick.svg';

import "../css/style.css";
import "../css/calendar.css";

const Step15_Preview_Abramovic = () => {
    const [posId, setPosId] = useState(null);

    const [event, setEvent] = useState({});

    const [ticketTypes, setTicketTypes] = useState([]);
    const [unlockedTicketTypes, setUnlockedTicketTypes] = useState([]);
    const [isTicketTypesUnlocked, setIsTicketTypesUnlocked] = useState(false);
    const [notAllowedPromotionCodes, setNotAllowedPromotionCodes] = useState([]);

    const [eventsUpgrade, setEventsUpgrade] = useState([]);
    const [eventGroup, setEventGroup] = useState({});
    const [orderId, setOrderId] = useState(0);
    const [order, setOrder] = useState({});

    const [eventIdPreviewAbramovic, setEventIdPreviewAbramovic] = useState(0);
    const [eventGroupIdAbramovic, setEventGroupIdAbramovic] = useState(0);

    const [eventDates, setEventDates] = useState([]);

    const [date, setDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState("");
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedEventId, setSelectedEventId] = useState(0);

    const today = new Date();
    const defaultMonth = today.getMonth() < 9 ? 9 : today.getMonth();
    const [currentMonth, setCurrentMonth] = useState(new Date(2024, defaultMonth, 1));

    const [promotioncode, setPromotioncode] = useState("");
    const [promotioncodeAdded, setPromotioncodeAdded] = useState("");
    const [promotionError, setPromotionError] = useState("");
    const [isPromotioncodeApplied, setIsPromotioncodeApplied] = useState("");


    const [isUpgradeClick, setIsUpgradeClick] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(true);

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    let navigate = useNavigate();
    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {

        setLoading(true);
        const loadToken = async () => {
            try {
                const res = await axios.get("form/token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
                api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
                await sessionStorage.setItem("token", res.data);
                requestFormSettings();
            } catch (error) {
                console.error(error);
            }
        };

        let currentOrderId = sessionStorage.getItem("OrderId");

        if (currentOrderId > 0) {
            setOrderId(currentOrderId);
        }

        const requestFormSettings = async () => {
            try {
                const res = await axios.get(`form/formsettings`);

                setPosId(res.data.posId)
                setEventIdPreviewAbramovic(res.data.eventIdOfPreview);
                setEventGroupIdAbramovic(res.data.eventGroupIdAbramovic);

                setNotAllowedPromotionCodes(res.data.notAllowedPromotionCodes);

                if (currentOrderId > 0) {
                    loadOrder(currentOrderId, true);
                } else {
                    startOrder(res.data.posId);
                }

            } catch (error) {
                console.error(error);
            }
        };
        loadToken();
        setLoading(false);
    }, []);

    useEffect(() => {

        setLoading(true);

        const requestResources = async () => {
            try {
                const res = await axios.get(`form/resources/${language}`);
                setResources(res.data?.translation);
            } catch (error) {
                console.error(error);
            }
        };

        requestResources();
        setLoading(false);
    }, [language]);

    useEffect(() => {
        if (orderId > 0 && eventGroupIdAbramovic > 0) {
            loadUpgradeEvents(eventGroupIdAbramovic, currentMonth);
        }
    }, [currentMonth, eventIdPreviewAbramovic]);

    useEffect(() => {
        if (order && orderId > 0 && eventIdPreviewAbramovic > 0) {
            //reload event when ticket quantity is selected
            //to display correct quantity in the dropdown
            loadEvent(eventIdPreviewAbramovic, orderId)
        }
    }, [order, orderId, promotioncodeAdded]);

    useEffect(() => {

        (async () => {
            let timeslotsByEventIds = [];

            const currentEventsByDate = eventsUpgrade.filter(e => e.start?.split('T')[0] === selectedDate);

            for (var i in currentEventsByDate) {

                const eventId = currentEventsByDate[i].id;

                let timeslotsOfEvent = await getTimeslotsOfEvent(eventId);

                const timeslotsWithEventId = timeslotsOfEvent.map(timeslot => ({
                    ...timeslot,
                    eventId: eventId
                }));

                if (timeslotsWithEventId && timeslotsWithEventId.length > 0) {
                    timeslotsByEventIds = [...timeslotsByEventIds, ...timeslotsWithEventId];
                }
            }

            setTimeSlots(timeslotsByEventIds);

        })();
    }, [selectedDate])

    const startOrder = async (posId) => {

        const order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId ?? 6585,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const res = await axios.post(`${baseUrl}/ShopBasket/Order`, order);

            await sessionStorage.setItem("OrderId", res.data.id);

            setOrderId(res.data.id);
            setCurrentMonth(new Date(2024, defaultMonth, 1));

            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    };

    const loadOrder = async (orderId, removeTickets) => {
        try {
            // Order
            let updatedOrder = await getOrderByOrderId(orderId);

            if (updatedOrder != null && updatedOrder.tickets != null && removeTickets) {

                let ticketTypeIdsToRemove = updatedOrder.tickets
                    .map(ticket => ticket.id);

                // DELETE request to remove tickets
                updatedOrder = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    data: { TicketsToRemove: ticketTypeIdsToRemove }
                });
            }

            setOrder(updatedOrder);

            //validate page if user can proceed
            let quantity = 0;

            if (updatedOrder != null && updatedOrder.tickets != null) {
                quantity = updatedOrder.tickets.length;
            }

            if (quantity > 0) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
            if (error.response.status === 404 || error.response.status === 403) {
                const newOrder = await startOrder(posId);
                return newOrder;
            }
        }
    }

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            // sorting it according to the language
            const eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            // TicketType
            const ticketTypes = await getTicketTypesByEventId(eventId);

            const updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId, promotioncodeAdded);

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                })
            )

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    const loadUpgradeEvents = async (eventGroupIdUpgrade, currentMonth) => {
        setLoading(true);

        try {
            // EventGroup
            const eventGroup = await axios.get(`${baseUrl}/EventGroup/${eventGroupIdUpgrade}`);

            const totalNumberOfEventsResponse = await axios.get(`${baseUrl}/EventGroup/${eventGroupIdUpgrade}/Events/Count`);
            const totalNumberOfEvents = totalNumberOfEventsResponse.data;
            const eventBatchSize = 100;
            let events = [];

            for (let i = 0; i <= totalNumberOfEvents; i += eventBatchSize) {
                const eventGroupEventsResponse = await axios.get(`${baseUrl}/EventGroup/Events?eventGroupId=${eventGroupIdUpgrade}&languageCode=${language}&numberToTake=${eventBatchSize}&numberToSkip=${i}`);
                events = events.concat(eventGroupEventsResponse.data?.events);
            }

            // Check if the event date is within the current month and in the future
            const eventsForCurrentMonth = events.filter((event) => {
                const eventDate = new Date(event.start);
                const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
                const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
                return eventDate >= startOfMonth && eventDate <= endOfMonth && eventDate >= today;
            });


            const datesArray = [];

            await Promise.all(eventsForCurrentMonth.map(async (event) => {
                const ticketCountResponse = await requestTicketCount(event.id);
                const theEvent = await getEventById(event.id);

                let soldOut = (theEvent?.maxTickets - ticketCountResponse) <= 0;

                if (!soldOut) {
                    datesArray.push(event.start?.split('T')[0]);
                }

                event.end = theEvent?.end;
                event.isSoldOut = soldOut;
                event.maxTickets = theEvent?.maxTickets;
                event.ticketCount = theEvent?.maxTickets - ticketCountResponse;
                event.eventGroupId = eventGroupIdAbramovic;

            })).then(() => {
                setEventDates(datesArray);
                setEventsUpgrade(events);
                setEventGroup(eventGroup.data)
                setLoading(false);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const requestTicketCount = async (id) => {
        try {
            const res = await axios.get(`${baseUrl}/Event/${id}/TicketsSold`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    };

    const tileClassName = ({ date }) => {
        const dateString = new Date(date);
        dateString.setDate(dateString.getDate() + 1);
        const formattedDate = dateString.toISOString().split('T')[0];
        return eventDates.includes(formattedDate) ? 'bold-date' : 'disabled-date';
    };

    const handleActiveStartDateChange = ({ activeStartDate }) => {
        setCurrentMonth(activeStartDate);

        // clear old values
        setSelectedDate("");
        setSelectedEventId(0);
    };

    const onClickDay = (date) => {
        let selectedDate = new Date(date);
        selectedDate.setDate(selectedDate.getDate() + 1);
        const formattedDate = selectedDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);

        // clear old values
        setSelectedEventId(0);
    };

    const onCheckedChange = (e) => setSelectedEventId(e.target.value);

    const getTimeslotsOfEvent = async (eventId) => {
        let timeslotsByEventId = await getTimeSlotsByEventId(eventId);

        return timeslotsByEventId;
    };

    const upgradeOrderToAllInclusiveTickets = async () => {


        //redirect to the all inclusive page
        navigate(`/${language}/all-inclusive`)
    }


    const onTicketTypeChange = (e, ticketTypeId) => {
        setLoading(true);

        const ticketToAdd = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        handleTicketsFromBasket(ticketToAdd);
    };

    const handleTicketsFromBasket = async (ticketToAdd) => {

        if (order != null && order.tickets != null) {
            let ticketTypeIdsToRemove = order.tickets
                .filter(ticket => ticket.ticketTypeId === ticketToAdd.ticketTypeId)
                .map(ticket => ticket.id);

            // DELETE request to remove tickets
            await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                data: { TicketsToRemove: ticketTypeIdsToRemove }
            });
        }

        let addTicketTypes = [];
        addTicketTypes.push(ticketToAdd);

        addTicketTypes = addTicketTypes.map((att) => {
            if (unlockedTicketTypes.includes(att.ticketTypeId)) {
                att.promotionCode = promotioncodeAdded;
            }
            return att;
        })

        if (ticketToAdd.quantity > 0) {
            await axios
                .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    ticketsToAdd: addTicketTypes,
                })
                .then(() => {
                    //// gtm - addToCart
                    //window.dataLayer = window.dataLayer || [];
                    //window.dataLayer.push({
                    //    'event': 'addToCart'
                    //});
                })
                .catch((error) => {
                    console.error(error.response.data);
                });
        }

        loadOrder(orderId, false);
    };

    const applyPromotionCodeToOrder = async (orderId, code) => {

        if (notAllowedPromotionCodes.includes(code.toLowerCase())) {
            setPromotionError(resources?.PromoCodeNotAllowed);
            return;
        }

        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            setIsPromotioncodeApplied(true);

            if (response?.data?.unlockedTicketTypes == null) {
                //when all the added tickets are unavailable we must display an error
                setPromotionError(resources?.PromoCodeBookedForToday);
            }
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(error.response.data.replace("_KH", ''));
            //setPromotionError(resources.detailPage?.errorMessage);
            setIsPromotioncodeApplied(false);
        }
    }

    const deletePromotionCodeFromOrder = async (orderId, code) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            setPromotioncode("");
            setPromotioncodeAdded("");
            setIsPromotioncodeApplied(false);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const getTicketTypeAvailability = async (ticketTypeId, orderId, promotionCode) => {
        try {
            const response = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}&promotionCode=${promotionCode}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const addPromotionToOrder = async () => {

        setPromotionError("");
        setLoading(true);

        //to prevent collisions with the current codes stored in our system we added the suffix "_KH" to the imported promotion codes to out DB.
        //the suffix is only added to the imported promotions, the randomly generated ones (the non numeric) won't hae the suffix in our DB
        const parsedPromotionCode = isNaN(promotioncode) ? promotioncode : `${promotioncode}_KH`;

        const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(orderId, parsedPromotionCode);
        let updatedTicketTypes = [];
        updatedTicketTypes = await Promise.all(ticketTypes.map(async (ticketType) => {


            if (updatedUnlockedTicketTypes?.unlockedTicketTypes?.includes(ticketType.id)) {
                const unlockedTicketTypeAvailability = await getTicketTypeAvailability(ticketType.id, orderId, parsedPromotionCode);
                ticketType.availability = unlockedTicketTypeAvailability;
            }
            return ticketType;
        }))

        //when all the added tickets are unavailable we must display an error
        //const allTicketTypesNotAvailable = updatedTicketTypes.filter(tt => updatedUnlockedTicketTypes?.unlockedTicketTypes?.includes(tt.id)).every(tt => tt.availability?.availableTickets === 0);
        //if (allTicketTypesNotAvailable) setPromotionError(resources?.PromoCodeBookedForToday);

        await sessionStorage.setItem("addedPromotion", parsedPromotionCode);
        setPromotioncodeAdded(parsedPromotionCode);

        setTicketTypes(updatedTicketTypes)
        setUnlockedTicketTypes(updatedUnlockedTicketTypes?.unlockedTicketTypes ?? []);
        setIsTicketTypesUnlocked(true);
        setLoading(false);

    }

    const removePromotionFromOrder = async () => {

        setPromotionError("");
        setLoading(true);

        //delete the tickets with the selected promotion
        if (order?.tickets) {
            const ticketTypeIdsToRemove = order.tickets.filter(t => t.promotionCode === promotioncodeAdded).map(ticket => ticket.id);

            if (ticketTypeIdsToRemove.length > 0) {
                const updatedOrder = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    data: { TicketsToRemove: ticketTypeIdsToRemove }
                });

                setOrder(updatedOrder);
            }
        }

        await deletePromotionCodeFromOrder(orderId, promotioncodeAdded);

        await loadEvent(eventIdPreviewAbramovic, orderId);

        setUnlockedTicketTypes([]);
        setIsTicketTypesUnlocked(false);
        setLoading(false);
    }


    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        //if (isTicketTypesUnlocked) {
        //    ticketTypesSorted = ticketTypesSorted.filter((tts) => unlockedTicketTypes.includes(tts.id));
        //}

        return (
            <div style={{ position: 'relative', opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'auto' }}>
                {ticketTypes &&
                    ticketTypes.length > 0 &&
                    ticketTypesSorted.map((tt, index) =>
                        (tt.availability?.availableTickets + (order.tickets?.filter((t) => t.ticketTypeId === tt.id)?.length ?? 0)) > 0 ? (
                            <div
                                key={index}
                                className="row pt-2 pb-2 padding-left-25 padding-right-25"
                                style={{ backgroundColor: index % 2 === 0 ? 'transparent' : '#EFF2F3' }}
                            >
                                <div className="col-6 col-md-7 text-16-18 black-color text-start d-flex align-items-center padding-0">
                                    <label>{tt.info?.name}</label>
                                </div>
                                <div className="col-1 col-md-2 text-16-18 black-color d-flex justify-content-end align-items-center">
                                    <label>{tt.currency}</label>
                                </div>
                                <div className="col-2 col-md-2 text-16-18 black-color d-flex justify-content-start align-items-center">
                                    <label className="price-label text-end">{tt.price.toFixed(2)}</label>
                                </div>
                                <div className="col-3 col-md-1 text-16-18 black-color d-flex justify-content-start align-items-center padding-0">
                                    <select
                                        className="form-select"
                                        value={order && order.tickets ? order.tickets.filter((t) => t.ticketTypeId === tt.id).length : 0}
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                        disabled={loading}
                                    >
                                        {Array.from(
                                            Array(
                                                tt.availability?.availableTickets +
                                                1 +
                                                (order.tickets ? order.tickets.filter((t) => t.ticketTypeId === tt.id).length : 0)
                                            ),
                                            (e, i) => (
                                                <option key={i} value={i}>
                                                    {i}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>
                        ) : null
                    )}
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 10,
                        }}
                    >
                        <Loading bgColor="transparent" color="#d3d3d3" position="relative" />
                    </div>
                )}
            </div>
        );
    };


    const getUpgradePrice = () => {


        var upgradeTotalPrice = 0;

        if (order && order.tickets) {

            for (let i = 0; i < order.tickets.length; i++) {
                if (order.tickets[i].price === 31)
                    upgradeTotalPrice += 5;
                else if (order.tickets[i].price === 22) {
                    upgradeTotalPrice += 3;
                }
            }
        }
        return upgradeTotalPrice;
    }


    const getUpgradeButtonText = () => {

        let text = resources?.UpgradeButtonText || '';

        var upgradeTotalPrice = getUpgradePrice();
        return text.replace('{TotalUpgradePrice}', upgradeTotalPrice);

    }

    const getUpgradeDescriptionText = () => {

        let text = resources?.UpgradeTicketDescription || '';

        var upgradeTotalPrice = getUpgradePrice();
        return text.replace('{TotalUpgradePrice}', upgradeTotalPrice);
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            {/*{loading && <Loading alignment="center" color="#d3d3d3" bgColor="transparent" position="fixed" top="300px" />}*/}
            <>
                <Navbar numberOfTickets={order?.tickets?.length ?? 0} isShoppingCartVisible={true} pagePath="matthew-wong-van-gogh" />
                <main className="flex-fill">
                    {resources && (
                        <div className="container page-wrapper">

                            <div className="progress-container">
                                <div className="progress-step active">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgressTick} alt="Step 1" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.DateAndTime}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step completed">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgress2} alt=" Step 2" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.TicketSelection}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgressGrey} alt="Step 3" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.SummaryAndContact}</div>
                                    </div>
                                    <div className="line"></div>
                                </div>

                                <div className="progress-step">
                                    <div>
                                        <div className="image">
                                            <img src={imgIconProgressGrey} alt="Step 4" />
                                        </div>
                                        <div className="step-number text-wrap visible-desktop">{resources?.PaymentAndDelivery}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 sticky-wrapper">
                                <div className="col-md-8 padding-0 margin-0">
                                    <p className="text-50-55 black-color font-500">{resources?.PreviewHeader}</p>
                                    <p className="text-30-36-bold black-color">24.10.2024</p>
                                </div>
                            </div>

                            <br />


                           {/* {mapTicketTypes()}*/}

                            {/* Mitglieder ticket desktop*/}

                            {/*<div className="row margin-top-55 div-container-blue visible-desktop padding-25">*/}
                            {/*    <div className="col-md-12 padding-0">*/}
                            {/*        <p className="text-30-37 font-900 text-uppercase black-color">{resources?.MitgliederTicket}</p>*/}
                            {/*        <p className=" text-16-18 black-color ">{resources?.MitgliederTicketDescription1}</p>*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-12 mb-2">*/}
                            {/*                <div className="d-flex justify-content-between align-items-center">*/}
                            {/*                    <div className="flex-grow-1 me-3">*/}
                            {/*                        <input*/}
                            {/*                            className="form-control"*/}
                            {/*                            placeholder={resources?.MitgliederNumberOrCode}*/}
                            {/*                            value={promotioncode}*/}
                            {/*                            onChange={(e) => setPromotioncode(e.target.value)}*/}
                            {/*                            disabled={isPromotioncodeApplied}*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                    <div>*/}
                            {/*                        <button className="btn grey-button" onClick={() => addPromotionToOrder()}>*/}
                            {/*                            {resources?.Redeem}*/}
                            {/*                        </button>*/}
                            {/*                    </div>*/}
                            {/*                    {isPromotioncodeApplied && (*/}
                            {/*                        <div>*/}
                            {/*                            <button className="btn grey-button ms-3" onClick={() => removePromotionFromOrder()}>*/}
                            {/*                                {resources?.Remove}*/}
                            {/*                            </button>*/}
                            {/*                        </div>*/}
                            {/*                    )}*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-12">*/}
                            {/*                {promotionError != null && promotionError !== "" && <p className="red-color">{promotionError}</p>}*/}
                            {/*                {(promotionError == null || promotionError === "")*/}
                            {/*                    && promotioncodeAdded != null*/}
                            {/*                    && promotioncodeAdded !== ""*/}
                            {/*                    && Array.isArray(ticketTypes)*/}
                            {/*                    && ticketTypes?.length > 0*/}
                            {/*                    && !(ticketTypes.some((tt => tt.availability?.availableTickets + (order?.tickets?.length > 0 ? (order?.tickets?.filter((t) => t.ticketTypeId === tt.id)?.length ?? 0) : 0) > 0)))*/}
                            {/*                    && <p className="red-color">{resources?.NoTicketTypesWithPromotionCode}</p>*/}
                            {/*                }*/}
                            {/*                {(promotionError == null || promotionError === "")*/}
                            {/*                    && promotioncodeAdded != null*/}
                            {/*                    && promotioncodeAdded !== ""*/}
                            {/*                    && Array.isArray(ticketTypes)*/}
                            {/*                    && ticketTypes.length > 0 &&*/}
                            {/*                    (ticketTypes.some((tt => tt.availability?.availableTickets + (order?.tickets?.length > 0 ? (order?.tickets?.filter((t) => t.ticketTypeId === tt.id)?.length ?? 0) : 0) > 0)))*/}
                            {/*                    &&*/}
                            {/*                    <p className="green-color">{resources?.SelectTicketTypeNumber}</p>*/}
                            {/*                }*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <p className=" text-16-18 black-color mb-2 padding-bottom-10">{resources?.MitgliederTicketDescription2}</p>*/}
                            {/*        <div>*/}
                            {/*            <ul className="list-unstyled">*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint1}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint2}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint3}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint4}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint5}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint6}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint7}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint8}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint9}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint10}</li>*/}
                            {/*                <li className="padding-bottom-15">{resources?.MitgliederTicketpoint11}</li>*/}
                            {/*                <li>{resources?.MitgliederTicketpoint12}</li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* Mitglieder ticket mobile*/}
                            {/*<div className="row visible-mobile">*/}
                            {/*    <div className="col-12">*/}
                            {/*        <div className="accordion" id="accordion-mitglieder-ticket">*/}
                            {/*            <div className="accordion-item">*/}
                            {/*                <h2 className="accordion-header" id="headingMitgliederTicket">*/}
                            {/*                    <button*/}
                            {/*                        className="accordion-button collapsed accordion-button-exhibition"*/}
                            {/*                        type="button"*/}
                            {/*                        data-bs-toggle="collapse"*/}
                            {/*                        data-bs-target="#collapseMitgliederTTicket"*/}
                            {/*                        aria-expanded="false"*/}
                            {/*                        aria-controls="collapseMitgliederTTicket"*/}
                            {/*                    >*/}
                            {/*                        <svg className="accordion-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                            <path d="M8 5l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />*/}
                            {/*                        </svg>*/}
                            {/*                        <label className="accordion-button-text">*/}
                            {/*                            <p className="text-40-45 font-900 text-uppercase black-color mt-3">{resources?.MitgliederTicket}</p>*/}
                            {/*                        </label>*/}
                            {/*                    </button>*/}
                            {/*                </h2>*/}
                            {/*                <div id="collapseMitgliederTTicket" className="accordion-collapse collapse" aria-labelledby="headingMitgliederTTicket" data-bs-parent="#accordion-mitglieder-ticket">*/}
                            {/*                    <div className="accordion-body">*/}
                            {/*                        <div className="row mt-3 ">*/}
                            {/*                            <div className="col-12">*/}
                            {/*                                <p className=" text-16-18 black-color ">{resources?.MitgliederTicketDescription1}</p>*/}
                            {/*                                <div className="row">*/}
                            {/*                                    <div className="col-12 mb-2">*/}
                            {/*                                        <input className="form-control" placeholder={resources?.MitgliederNumberOrCode} value={promotioncode}*/}
                            {/*                                            onChange={(e) => setPromotioncode(e.target.value)}*/}
                            {/*                                            disabled={isPromotioncodeApplied} />*/}

                            {/*                                        <p>{promotionError}</p>*/}
                            {/*                                    </div>*/}
                            {/*                                    <div className="col-12 mb-2 pe-0">*/}
                            {/*                                        <div className="row">*/}
                            {/*                                            <div className="col text-start ">*/}
                            {/*                                                <button className="btn grey-button" onClick={() => { addPromotionToOrder() }}>{resources?.Redeem}</button>*/}
                            {/*                                                {isPromotioncodeApplied &&*/}
                            {/*                                                    <button className="btn grey-button ms-3" onClick={() => { removePromotionFromOrder() }}>{resources?.Remove}</button>}*/}
                            {/*                                            </div>*/}
                            {/*                                        </div>*/}
                            {/*                                    </div>*/}
                            {/*                                </div>*/}
                            {/*                                <p className=" text-16-18 black-color mt-2 mb-2 padding-bottom-10">{resources?.MitgliederTicketDescription2}</p>*/}
                            {/*                                <div>*/}
                            {/*                                    <ul className="list-unstyled">*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint1}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint2}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint3}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint4}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint5}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint6}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint7}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint8}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint9}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint10}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint11}</li>*/}
                            {/*                                        <li className="padding-bottom-15">{resources?.MitgliederTicketpoint12}</li>*/}
                            {/*                                    </ul>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="row mt-5">*/}
                            {/*    <div className="col-md-7 col-6 text-18-21 black-color d-flex justify-content-start align-items-center padding-0">{resources.Total}:</div>*/}
                            {/*    <div className="col-1 col-md-2 text-18-21 black-color d-flex justify-content-end align-items-center">*/}
                            {/*        <label className="font-600 padding-right-10">*/}
                            {/*            CHF*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-2 col-md-1 text-18-21 black-color d-flex justify-content-start align-items-center">*/}
                            {/*        <label className="font-600 price-label text-end padding-right-10"> {order && order.total && order.total !== null ? (*/}
                            {/*            <span>{(order.total).toFixed(2)}</span>*/}
                            {/*        ) : (*/}
                            {/*            <span>0.00</span>*/}
                            {/*        )}*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div>
                                <p className="text-20-22 dark-grey-color flex-wrap"
                                    dangerouslySetInnerHTML={{ __html: resources?.FullyBookedSpan }}></p>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-6 d-flex justify-content-start align-items-center padding-0">
                                    <button className="btn transperant-grey-button" onClick={() => navigate(-1)}>
                                        {resources?._Back}
                                    </button>
                                </div>
                                <div className="col-6 d-flex justify-content-end align-items-center padding-0">
                                    <button
                                        className="btn grey-button "
                                        disabled={!isValid}
                                        onClick={() => navigate(`/${language}/shipping`)}
                                    >  {resources?.BookNow}
                                    </button>
                                </div>
                            </div>
                            <InformationAndTariff />
                        </div>
                    )}
                </main>
                <Footer />
            </>
        </div>
    );
};

export default Step15_Preview_Abramovic;